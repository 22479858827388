<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="cta_prev_next">
          <a
              id="reactive_cta_next"
              ref="reactive_cta_next"
              class="cta_next cursor_pointer"
              @click="submitInformations"
          >
            <span class="icon">
              <img
                  alt="Arrow prev"
                  border="0"
                  src="../assets/images/arrow_cta_next.svg"
              />
            </span>
          </a>
        </div>

        <div class="content_retix">
          <div class="container-retix">
            <!-- -->
            <div class="row">
              <div class="col-12">
                <h1 v-if="!forms.customerForm.customerIdentityForm.companyName">
                  Nouveau client
                </h1>
                <h1 v-if="forms.customerForm.customerIdentityForm.companyName">
                  {{ forms.customerForm.customerIdentityForm.companyName }}
                </h1>
                <span class="step_customer">( Étape 1/4 )</span>
              </div>
            </div>
            <!-- -->
            <div class="row">
              <div class="col-12">
                <div class="timeline">
                  <div class="row">
                    <div
                        id="wizzard_informations"
                        class="col done"
                        @click="goTo('/information')"
                    >
                      <div class="dot_timeline">
                        <span class="minidot_timeline"></span>
                      </div>
                      <span class="txt_timeline"> Informations </span>
                    </div>
                    <div
                        id="wizzard_load"
                        class="col"
                        @click="goTo('/load_curve')"
                    >
                      <div class="dot_timeline">
                        <span class="minidot_timeline"></span>
                      </div>
                      <span class="txt_timeline"> Courbe de charge </span>
                    </div>
                    <div
                        id="wizzard_goals"
                        class="col actif"
                        @click="goTo('/goal')"
                    >
                      <div class="dot_timeline">
                        <span class="minidot_timeline"></span>
                      </div>
                      <span class="txt_timeline"> Objectifs </span>
                    </div>
                    <div
                        id="wizzard_scenarii"
                        class="col"
                        @click="goTo('/scenario')"
                    >
                      <div class="dot_timeline">
                        <span class="minidot_timeline"></span>
                      </div>
                      <span class="txt_timeline"> Scenarii </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="content_customer">
              <ul class="row">
                <div class="col-4">
                  <customerIdentityForm />
                  <div class="pt-5"></div>
                  <Project-identity />
                </div>
                <div class="col-4">
                  <electricalIdentityForm />
                  <div class="pt-5"></div>
                  <User-identification :showWarning="showWarning" style="padding-left: 0px; padding-right: 30px"/>
                </div>
                <div class="col-4">
                  <projectParametersForm style="padding-left: 0px"/>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalError :message="errorMessage" :title="errorTitle" />
  </div>
</template>

<script>
import customerIdentityForm from "../components/Client_form";
import electricalIdentityForm from "../components/Electrical_form";
import projectParametersForm from "../components/Project_form";
import validation from "../utils/validation";
import util from "../utils/util";
import customerUtil from "../utils/customer";
import ModalError from "../components/ModalError";
import ProjectIdentity from "../components/ProjectIdentity_form.vue";
import UserIdentification from "../components/UserIdentification_form.vue";
import Vue from 'vue';

export default {
  props: ["source", "type"],
  data() {
    return {
      previousForm: {},
      errorMessage: "",
      errorTitle: "",
      proceedToNext: false,
      showWarning: false, // New data property to control the warning message
    };
  },
  mounted() {
    if (this.type === "creation") {
      util.initForms(this.forms, this.type);
    }

    this.validatecustomerForm();
    this.previousForm = JSON.parse(JSON.stringify(this.forms.customerForm));
    if (this.type !== "creation") {
      this.selectCustomer();
    }
    this.updateWizard();
  },
  watch: {
    forms: {
      handler(newVal, oldVal) {
        this.validatecustomerForm();
      },
      deep: true,
    },
  },
  methods: {
    updateWizard: function () {
      document.getElementById("wizzard_informations").classList.add("actif");
      document.getElementById("wizzard_goals").classList.remove("actif");
      document.getElementById("wizzard_load").classList.remove("actif");
      document.getElementById("wizzard_scenarii").classList.remove("actif");
    },
    goTo: function (route) {
      if (
          route === "/load_curve" &&
          validation.isLoadCurveAnalyseValid(this.forms.customerForm.consumptionCurveForm)
      ) {
        this.$router.push(route);
      }
      if (route === "/scenario" && forms.customerForm.scenariosForm) {
        this.$router.push(route);
      }
      if (route === "/goal" && forms.customerForm.consumptionCurveForm.annualConsumption) {
        this.$router.push(route);
      }
      if (route === "/information") {
        this.$router.push(route);
      }
    },
    async selectCustomer() {
      const id = window.localStorage.getItem("customerId")
          ? JSON.parse(window.localStorage.getItem("customerId"))
          : -1;
      if ((!forms.id || forms.id < 0) && id >= 0) {
        await this.$store.dispatch("customer/get", { id });
        const response = this.$store.getters["customer/get"];

        if (response.error != null) {
          this.errorTitle = "Get error";
          this.errorMessage = util.getErrorMessage(response.error);
          this.$bvModal.show("error-modal");
        } else {
          customerUtil.updateCustomerForm(response, forms, id);
        }
      }
    },
    validatecustomerForm: function () {
      if (validation.isInformationValid(this.forms.customerForm)) {
        document
            .getElementById("reactive_cta_next")
            .classList.add("cta_next_valid");
      } else {
        document
            .getElementById("reactive_cta_next")
            .classList.remove("cta_next_valid");
      }
    },
    async submitInformations() {
      this.showWarning = true; // Show the warning message

      if (!forms.id || forms.id <= 0) {
        const objectToCreate = {
          customerIdentity: forms.customerForm.customerIdentityForm,
          electricalIdentity: forms.customerForm.electricalIdentityForm,
          consumptionCurve: null,
          simulationGoals: null,
          projectParameters: forms.customerForm.projectParametersForm,
          projectIdentity: forms.customerForm.projectIdentityForm,
          userIdentification: forms.customerForm.userIdentificationForm,
          scenario: null,
          scenariosView: null,
          simulation: null,
          createdAt: null,
          updatedAt: null,
          userId: null,
        };
        await this.$store.dispatch("customer/create", objectToCreate);
        const customerCreateResponse = await this.$store.getters[
            "customer/getCreated"
            ];

        if (customerCreateResponse.error) {
          const userIdentification = objectToCreate.userIdentification;
          if (!userIdentification.email) {
            Vue.toasted.error("Email vide");
          } else if (!userIdentification.password) {
            Vue.toasted.error("Mot de passe vide");
          } else {
            Vue.toasted.error("L'email est déjà utilisé par un autre utilisateur");
          }
          return;
        } else {
          forms.id = customerCreateResponse.id;
          this.$router.push({ name: "load_curve" });
        }
      } else {
        let objectToUpdate = {
          customerId: window.localStorage.getItem("customerId"),
          customerIdentity: this.previousForm.customerIdentityForm,
          electricalIdentity: this.previousForm.electricalIdentityForm,
          consumptionCurve: this.previousForm.consumptionCurveForm,
          simulationGoals: this.previousForm.simulationGoalsForm,
          projectParameters: this.previousForm.projectParametersForm,
          projectIdentity: this.previousForm.projectIdentityForm,
          userIdentification: this.previousForm.userIdentificationForm,
          scenario: null,
          scenariosView: this.previousForm.scenariosForm,
          simulation: this.previousForm.simulationForm,
          createdAt: this.previousForm.createdAt,
          updatedAt: this.previousForm.updatedAt
        };

        let flagModif = false;
        if (util.isFormUpdated(this.previousForm.customerIdentityForm, forms.customerForm.customerIdentityForm)) {
          objectToUpdate.customerIdentity = forms.customerForm.customerIdentityForm;
          flagModif = true;
        }

        if (
            util.isFormUpdated(
                this.previousForm.electricalIdentityForm,
                forms.customerForm.electricalIdentityForm
            )
        ) {
          objectToUpdate.electricalIdentity =
              forms.customerForm.electricalIdentityForm;
          flagModif = true;
        }

        if (
            util.isFormUpdated(
                this.previousForm.projectParametersForm,
                forms.customerForm.projectParametersForm
            )
        ) {
          objectToUpdate.projectParameters =
              forms.customerForm.projectParametersForm;
          flagModif = true;
        }

        if (
            util.isFormUpdated(
                this.previousForm.projectIdentityForm,
                forms.customerForm.projectIdentityForm
            )
        ) {
          objectToUpdate.projectIdentity =
              forms.customerForm.projectIdentityForm;
          flagModif = true;
        }
        if (
            util.isFormUpdated(
                this.previousForm.userIdentificationForm,
                forms.customerForm.userIdentificationForm
            )
        ) {
          objectToUpdate.userIdentification =
              forms.customerForm.userIdentificationForm;
          flagModif = true;
        }

        if (flagModif) {
          await this.$store.dispatch("customer/update", objectToUpdate);
          const customerUpdateResponse = await this.$store.getters[
              "customer/getUpdated"
              ];

          if (customerUpdateResponse.error) {
            Vue.toasted.error("L'email est déjà utilisé par un autre utilisateur");
            return;
          }
        }
      }
      this.$router.push({name: "load_curve"});
    },
  },
  components: {
    customerIdentityForm,
    electricalIdentityForm,
    projectParametersForm,
    ModalError,
    ProjectIdentity,
    UserIdentification,
  },
};
</script>
